<template>
    <div class="section-one w-100 pt-10 pt-4 mb-0 mb-lg-4">
        <div class="row justify-content-center min-vh-100">
            <div class="col-10 py-0">
                <div class="row justify-content-end mb-4">
                    <div class="col-lg-4 col-9">
                        <input type="text" v-model="search" class="form-control w-100" placeholder="Search here...">
                    </div>
                    <div class="col-lg-1 col-3 pl-0">
                        <button @click="doSearch()" class="btn btn-info btn-lg btn-block"><i class="fas fa-search"></i></button>
                    </div>
                </div>
                <div class="row justify-content-center" v-if="!isLoad">
                    <div class="col-11" v-if="article">
                        <div class="row">
                            <div class="col-lg-8 mb-5 text-black">
                                <div class="row">
                                    <div class="col-12 text-center mb-3">
                                        <h2 class="lh-2 display2-size display2-md-size mont-font text-grey-900 fw-700">{{article.title}}</h2>                                
                                        <h6 class="font-xssss text-grey-500 fw-600 mt-0 d-inline-block"><i class="ti-user mr-2"></i> {{article.created_name}}</h6>
                                    </div>
                                </div>
                                <div class="row justify-content-center mb-5">
                                    <div class="col-lg-5">
                                        <img :src=" article.thumbnail" alt="blog-image" class="img-fluid rounded-lg">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="ck-content" v-html="article.content"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="row justify-content-center mb-5 d-none">
                                    <div class="col-lg-12 text-center">
                                        <h6 class="text-uppercase fw-900 ls-3 text-current font-md">Smart Courses Recomendation</h6>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-12 mb-3" v-for="(item, index) in scr" :key="index">
                                                <div class="card-course">
                                                    <div class="card-image">
                                                        <img :src="item.banner" width="100%" alt="">
                                                    </div>
                                                    <div class="card-content text-black font-xss py-3 px-lg-4 px-2">
                                                        <p class="text-black text-center fw-700 font-md">{{ item.name }}</p>
                                                        <hr>
                                                        <p class="text-black fw-500 font-xss">This course includes:</p>
                                                        <table>
                                                            <tr>
                                                                <td class="px-2 py-2"><img :src="'/images/bg-blog-detail/video-logo.svg'" width="25px" alt=""></td>
                                                                <td><span class="text-black fw-500 font-xsss">21 Hours on-demand video</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-2"><img :src="'/images/bg-blog-detail/article-logo.svg'" width="25px" alt=""></td>
                                                                <td><span class="text-black fw-500 font-xsss">Articles</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-2"><img :src="'/images/bg-blog-detail/download-logo.svg'" width="25px" alt=""></td>
                                                                <td><span class="text-black fw-500 font-xsss">Downloadable resources</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-2"><img :src="'/images/bg-blog-detail/infinite-logo.svg'" width="25px" alt=""></td>
                                                                <td><span class="text-black fw-500 font-xsss">Full time access</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-2"><img :src="'/images/bg-blog-detail/mobile-logo.svg'" width="25px" alt=""></td>
                                                                <td><span class="text-black fw-500 font-xsss">Access on mobile and TV</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-2"><img :src="'/images/bg-blog-detail/trophy-logo.svg'" width="25px" alt=""></td>
                                                                <td><span class="text-black fw-500 font-xsss">Certificate of completion</span></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="card-bottom py-3 text-center">
                                                        <router-link v-if="isAuth" :to="{name: 'CourseDetails', params: {idCourse: item.slug}}" class="text-black fw-400 font-xsss text-underline"><u>learn more</u></router-link>
                                                        <router-link v-else :to="{name: 'Login'}" class="text-black fw-400 font-xsss text-underline"><u>learn more</u></router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-5 pt-5">
                            <div class="col-lg-12">
                                <h6 class="text-uppercase fw-900 ls-3 text-current font-md">Comments</h6>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-3">
                            <div class="col-lg-12">
                                <SectionTwo @authentication="isAuth = true"></SectionTwo>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center py-3" v-else>
                        <img :src="'/images/empty.png'" alt="" width="300">
                        <h1 class="text-muted">Data Empty</h1>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-12 text-center py-5">
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import SectionTwo from './SectionTwo.vue'
export default {
    components: {
        SectionTwo
    },
    data(){
        return {
            idArticle: this.$route.params.idBlog,
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            isAuth: false,
            article: null,
            scr: [],
            search: '',
            popular: {
                rewind : true,
                perPage : 3,
                gap: '1rem',
                autoplay : true,
                arrows : true,
                pagination: false,
                breakpoints: {
                    '992' : {
                        perPage: 2,
                    },
                    '800' : {
                        perPage: 2,
                    },
                    '768' : {
                        perPage: 1,
                    },
                    '480' : {
                        perPage: 1,
                    },
                }
            },
        }
    },
    created(){
        this.getArticle();
    },
    methods: {
        async getArticle(){
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/blog/article/detail?id=${this.idArticle}`)
            .then(async (res) => {
                this.article = res.data.data
                await axios.get(`${process.env.VUE_APP_URL_API}/guest/blog/scr/get-by-article-id/${this.article.id}`)
                .then(resp => {
                    this.scr = resp.data.data
                }).finally(() => this.isLoad = false)
            })
        },
        doSearch() {
            if(this.search) {
                this.$router.push({ name: 'BlogListBrowseNew', query: {search: this.search} })
            }
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 991.5px) {
    .d-none {
        display: inherit !important;
    }
}
.card-course .card-image img {
    border-radius: 20px 20px 0px 0px;
}
.card-course {
    background-color: #7FCDB2;
    border-radius: 20px;
}
</style>