<template>
    <div class="row">
        <div class="col-12 py-0 mb-4">
            <form @submit.prevent="submitComment()" class="comments-list">
                <div class="section full">
                    <div class="row">
                        <div class="form-group col-12">
                            <textarea v-model="message" rows="1" class="form-control" :placeholder="isAuth ? 'Insert your comment' : 'You need to Login!'" :disabled="!isAuth"></textarea>
                        </div>
                        <div class="col-12 text-right">
                             <button type="submit" class="btn btn-current fw-500" :disabled="!isAuth"><i class="fas fa-paper-plane mr-2"></i>Send</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12" v-if="!isLoad">
            <div class="comments-list">
                <div class="section full mb-5">
                    <div class="row bg-grey rounded-lg p-2 mb-2" v-for="(item, index) in comments" :key="index">
                        <div class="col-1">
                            <figure class="avatar mr-0">
                                <a href="javascript:void(0)" class="profile-detail-bttn"><img :src=" item.img" class="rounded-circle w75" alt="image"></a>
                            </figure>
                        </div>
                        <div class="col-11">
                            <h6 class="text-grey-500 mt-0 d-block fw-500 mb-1 ls-2">{{ item.created_at | datetime }} {{ item.is_edited ? '( Edited )' : '' }}</h6>
                            <div class="float-right">
                                <a href="javascript:void(0)" @click="item.editable = !item.editable" v-if="isAuth && user.id == item.user_id" class="font-xssss"><i class="fas fa-edit"></i></a>
                                <a href="javascript:void(0)" @click="deleteComment(item.id)" v-if="isAuth && user.id == item.user_id" class="font-xssss text-danger ml-2"><i class="fas fa-trash"></i></a>
                            </div>
                            <h4 class="mt-1 font-xss text-current mb-0 fw-700">{{ item.created_by }}</h4>
                            <p v-if="!item.editable" class="font-xsss fw-700 lh-26 text-grey-900 mb-1 mt-1">{{ item.message }}</p>
                            <form v-else @submit.prevent="patchComment(index)" class="row p-0">
                                <div class="form-group col-12 p-0">
                                    <textarea v-model="item.message" rows="1" class="form-control" :placeholder="isAuth ? 'Insert your comment' : 'You need to Login!'" :disabled="!isAuth"></textarea>
                                </div>
                                <div class="col-12 text-right p-0">
                                    <button type="submit" class="btn btn-sm btn-current" :disabled="!isAuth">Submit</button>
                                </div>
                            </form>
                            <hr class="mt-0">
                            <form @submit.prevent="submitReply(item.id, index)" class="row justify-content-between">
                                <div class="col-lg-11 col-10">
                                    <input type="text" class="form-control form-control-sm" style="height: 39px !important" placeholder="Enter Reply" v-model="item.message_input">
                                </div>
                                <div class="col-lg-1 col-2 px-0"><button type="submit" class="btn btn-current btn-block"><i class="ti-arrow-right text-white"></i></button></div>
                            </form>
                            <a class="font-xsssss ls-3 text-uppercase fw-700" href="javascript:void(0)" v-if="item.replies == 0">{{ item.replies }} Replies</a>
                            <a class="font-xsssss ls-3 text-uppercase fw-700" @click="getReplies(item.id, index)" href="javascript:void(0)" v-else>Load {{ item.replies }} Replies</a>
                            <div class="section full mb-5" v-if="item.data_repliest.length">
                                <div class="row p-2" v-for="(items, indexs) in item.data_repliest" :key="indexs">
                                    <div class="col-1">
                                        <figure class="avatar mr-0">
                                            <a href="javascript:void(0)" class="profile-detail-bttn"><img :src=" item.img" class="rounded-circle w75" alt="image"></a>
                                        </figure>
                                    </div>
                                    <div class="col-11">
                                        <h6 class="text-grey-500 mb-1 mt-0 d-block fw-500 mb-1 ls-2">{{ items.created_at | datetime }}</h6>
                                        <div class="float-right">
                                            <a href="javascript:void(0)" @click="items.editable = !items.editable" v-if="isAuth && user.id == items.user_id" class="font-xssss"><i class="fas fa-edit"></i></a>
                                            <a href="javascript:void(0)" @click="deleteReply(index, items.id)" v-if="isAuth && user.id == items.user_id" class="font-xssss text-danger ml-2"><i class="fas fa-trash"></i></a>
                                        </div>
                                        <h4 class="mt-1 font-xss text-current mb-0 fw-700">{{ items.created_by }}</h4>
                                        <p v-if="!items.editable" class="font-xsss fw-700 lh-26 text-grey-900 mb-1 mt-1">{{ items.message }}</p>
                                        <form v-else @submit.prevent="patchReply(index, indexs)" class="row p-0">
                                            <div class="form-group col-12 p-0">
                                                <textarea v-model="items.message" rows="1" class="form-control" :placeholder="isAuth ? 'Insert your comment' : 'You need to Login!'" :disabled="!isAuth"></textarea>
                                            </div>
                                            <div class="col-12 text-right p-0">
                                                <button type="submit" class="btn btn-sm btn-current" :disabled="!isAuth">Submit</button>
                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data() {
        return {
            hasToken : localStorage.getItem('token'),
            isAuth: false,
            media: process.env.VUE_APP_URL_CLOUD,
            idArticle: this.$route.params.idBlog,
            isLoad: true,
            message: '',
            comments: []
        }
    },
    created() {
        this.getUser()
        this.getComments()
    },
    methods: {
        async getUser() {   
            if(this.hasToken) {
                await axios.get(`${process.env.VUE_APP_URL_API}/user`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.user = res.data
                    this.isAuth = true
                    this.$emit('authentication', true)
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        },
        async getComments() {
            await axios.get(`${process.env.VUE_APP_URL_API}/comment/get-by-article-id/${this.idArticle}`)
            .then(async (res) => {
                if(res.data.data.length > 0) {
                    res.data.data.map((item) => {
                        item.message_input = ''
                        item.data_repliest = []
                        item.editable = false
                    })
                }
                this.comments = res.data.data
            }).finally(() => this.isLoad = false)
        },
        async getReplies(commentId, index) {
            await axios.get(`${process.env.VUE_APP_URL_API}/comment/get-by-article-id-and-comment-id/${this.idArticle}/${commentId}`)
            .then(async (res) => {
                if(res.data.data.length > 0) {
                    res.data.data.map((item) => {
                        item.editable = false
                    })
                }
                this.comments[index].data_repliest = res.data.data
            }).finally(() => this.isLoad = false)
        },
        async submitComment() {
            let data = new FormData();
            data.append('article_id', this.idArticle);
            data.append('message', this.message);
            await axios.post(`${process.env.VUE_APP_URL_API}/comment/create`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(() => {
                this.message = ''
                this.getComments()
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Comments',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async patchComment(index) {
            let comment = this.comments[index];
            let data = new FormData();
            data.append('article_id', this.idArticle);
            data.append('message', comment.message);
            data.append('id', comment.id);
            data.append('_method', 'PUT');
            await axios.post(`${process.env.VUE_APP_URL_API}/comment/update`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(() => {
                this.comments[index].editable = false;
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Comments',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async patchReply(index, indexs) {
            let comment = this.comments[index].data_repliest[indexs];
            let data = new FormData();
            data.append('article_id', this.idArticle);
            data.append('message', comment.message);
            data.append('id', comment.id);
            data.append('_method', 'PUT');
            await axios.post(`${process.env.VUE_APP_URL_API}/comment/update`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(() => {
                this.comments[index].data_repliest[indexs].editable = false;
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Comments',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async submitReply(commentId, index) {
            let data = new FormData();
            data.append('article_id', this.idArticle);
            data.append('comment_id', commentId);
            data.append('message', this.comments[index].message_input);
            await axios.post(`${process.env.VUE_APP_URL_API}/comment/create`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(() => {
                this.comments[index].message_input = ''
                this.getReplies(commentId, index)
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Comments',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        deleteComment(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    var data = {
                        id: id,
                        _method: 'delete'
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/comment/delete`, data, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.token}`
                        },
                    }).then(() => {
                        this.getComments()
                    })
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'Comments',
                        text: 'Your data has been deleted !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        deleteReply(index, id) {
            let comment = this.comments[index]
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    var data = {
                        id: id,
                        _method: 'delete'
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/comment/delete`, data, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.token}`
                        },
                    }).then(() => {
                        this.getReplies(comment.id, index)
                    })
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'Comments',
                        text: 'Your data has been deleted !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    },
    filters:{
        datetime: function (date) {
            return moment(date).calendar();
        }
    },
}
</script>