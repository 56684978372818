<template>
    <div class="row m-0 p-0">
        <div class="col-12 px-0 pt-7 m-0">
            <SectionOne></SectionOne>
        </div>
    </div>
</template>
<script>
import SectionOne from './Section/SectionOne.vue'
export default {
    components: {
        SectionOne
    }
}
</script>